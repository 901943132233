import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <h1 className="text-center">Страница не обнаружена</h1>
    <p className="text-center">Попробуйте ввести другой адрес или перейдите на  <Link to="/" className="text-dark"><u>главную страницу</u></Link>.</p>
  </Layout>
)

export default NotFoundPage
